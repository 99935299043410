import payload_plugin_qLmFnukI99 from "/tmp/build_d80742d5/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/tmp/build_d80742d5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/tmp/build_d80742d5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/tmp/build_d80742d5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/tmp/build_d80742d5/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/tmp/build_d80742d5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/tmp/build_d80742d5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/tmp/build_d80742d5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_2LpVRIemQY from "/tmp/build_d80742d5/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_UciE0i6zes from "/tmp/build_d80742d5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/tmp/build_d80742d5/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_zlvi6dcIsi from "/tmp/build_d80742d5/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/tmp/build_d80742d5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_kAXOMGr4vi from "/tmp/build_d80742d5/modules/@nuxt-google-sign-in/runtime/plugins/plugin.ts";
import dynamic_Tq03v2o7Cv from "/tmp/build_d80742d5/modules/@nuxt-dayjs/runtime/plugins/dynamic.ts";
import plugin_1FwAzOQ7ii from "/tmp/build_d80742d5/modules/@nuxt-dayjs/runtime/plugins/plugin.ts";
import i18n_VikgEE4zhR from "/tmp/build_d80742d5/modules/@nuxt-dayjs/runtime/plugins/i18n.ts";
import theme_J2PEhv0i9A from "/tmp/build_d80742d5/modules/@nuxt-vuetify/runtime/plugins/theme.ts";
import locale_uL4AyYiTYJ from "/tmp/build_d80742d5/modules/@nuxt-vuetify/runtime/plugins/locale.ts";
import date_3udTswtGeS from "/tmp/build_d80742d5/modules/@nuxt-vuetify/runtime/plugins/date.ts";
import i18n_hMJDfWXkeK from "/tmp/build_d80742d5/modules/@nuxt-vuetify/runtime/plugins/i18n.ts";
import pinia_cJBLAWWm6y from "/tmp/build_d80742d5/node_modules/@xarenas107/nuxt-socket-io/dist/runtime/plugins/pinia.js";
import plugin_QPWYutZQOD from "/tmp/build_d80742d5/node_modules/@xarenas107/nuxt-socket-io/dist/runtime/plugins/plugin.js";
import plugin_client_UYiXMU8ZyN from "/tmp/build_d80742d5/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import i18n_Ug908EZNww from "/tmp/build_d80742d5/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import switch_locale_path_ssr_5csfIgkrBP from "/tmp/build_d80742d5/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_VrpP1JaYg8 from "/tmp/build_d80742d5/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_sq1MuCrqbC from "/tmp/build_d80742d5/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import pwa_icons_plugin_iRYm12t59B from "/tmp/build_d80742d5/node_modules/.cache/nuxt/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/tmp/build_d80742d5/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import _36fetch_kc0D8LRcT2 from "/tmp/build_d80742d5/app/plugins/$fetch.ts";
import _36pinia_reset_rsBKvqkvTR from "/tmp/build_d80742d5/app/plugins/$pinia.reset.ts";
import maska_directive_wtpvxfVGeG from "/tmp/build_d80742d5/app/plugins/maska.directive.ts";
import vuetify_config_3BbPjaoUxi from "/tmp/build_d80742d5/app/plugins/vuetify.config.ts";
import ssg_detect_3fHkBxLtv0 from "/tmp/build_d80742d5/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
import oneBrowserTab_client_RnX07CovBR from "/tmp/build_d80742d5/app/plugins/oneBrowserTab.client.ts";
import vuetify_nuxt_plugin_Vbc1OyYG2r from "/tmp/build_d80742d5/node_modules/.cache/nuxt/.nuxt/vuetify-nuxt-plugin.mjs";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  view_transitions_client_2LpVRIemQY,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_zlvi6dcIsi,
  prefetch_client_5tzzN0oIVL,
  plugin_kAXOMGr4vi,
  dynamic_Tq03v2o7Cv,
  plugin_1FwAzOQ7ii,
  i18n_VikgEE4zhR,
  theme_J2PEhv0i9A,
  locale_uL4AyYiTYJ,
  date_3udTswtGeS,
  i18n_hMJDfWXkeK,
  pinia_cJBLAWWm6y,
  plugin_QPWYutZQOD,
  plugin_client_UYiXMU8ZyN,
  i18n_Ug908EZNww,
  switch_locale_path_ssr_5csfIgkrBP,
  route_locale_detect_VrpP1JaYg8,
  i18n_sq1MuCrqbC,
  pwa_icons_plugin_iRYm12t59B,
  pwa_client_ZuYw5i1i7i,
  _36fetch_kc0D8LRcT2,
  _36pinia_reset_rsBKvqkvTR,
  maska_directive_wtpvxfVGeG,
  vuetify_config_3BbPjaoUxi,
  ssg_detect_3fHkBxLtv0,
  oneBrowserTab_client_RnX07CovBR,
  vuetify_nuxt_plugin_Vbc1OyYG2r
]