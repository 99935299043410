import validate from "/tmp/build_d80742d5/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/tmp/build_d80742d5/app/middleware/auth.global.ts";
import disable_45vue_45transitions_45global from "/tmp/build_d80742d5/app/middleware/disableVueTransitions.global.ts";
import disabled_45global from "/tmp/build_d80742d5/app/middleware/disabled.global.ts";
import role_45global from "/tmp/build_d80742d5/app/middleware/role.global.ts";
import manifest_45route_45rule from "/tmp/build_d80742d5/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  disable_45vue_45transitions_45global,
  disabled_45global,
  role_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  scope: () => import("/tmp/build_d80742d5/app/middleware/scope.ts")
}