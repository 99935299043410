
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as calendarzv08NQC4T3Meta } from "/tmp/build_d80742d5/app/pages/[role]/calendar.vue?macro=true";
import { default as chats59OpBeyMKiMeta } from "/tmp/build_d80742d5/app/pages/[role]/chats.vue?macro=true";
import { default as _91token_93rWB5psZBgxMeta } from "/tmp/build_d80742d5/app/pages/[role]/email/validation/[token].vue?macro=true";
import { default as homed0HvrluBUCMeta } from "/tmp/build_d80742d5/app/pages/[role]/home.vue?macro=true";
import { default as patients8W6CxUrM6xMeta } from "/tmp/build_d80742d5/app/pages/[role]/patients.vue?macro=true";
import { default as settingsSJ9NgreuzeMeta } from "/tmp/build_d80742d5/app/pages/[role]/settings.vue?macro=true";
import { default as userszBQqg0DYXuMeta } from "/tmp/build_d80742d5/app/pages/[role]/users.vue?macro=true";
import { default as _91role_93vn19ShF5AkMeta } from "/tmp/build_d80742d5/app/pages/[role].vue?macro=true";
import { default as indexvBfDd72bnhMeta } from "/tmp/build_d80742d5/app/pages/index.vue?macro=true";
import { default as recipel4Iw2JKPygMeta } from "/tmp/build_d80742d5/app/pages/print/recipe.vue?macro=true";
import { default as shiftPAyJfzy0bVMeta } from "/tmp/build_d80742d5/app/pages/shift.vue?macro=true";
import { default as inYdIUksT8qhMeta } from "/tmp/build_d80742d5/app/pages/sign/in.vue?macro=true";
import { default as indexDZkjtWR1g2Meta } from "/tmp/build_d80742d5/app/pages/sign/index.vue?macro=true";
import { default as _91token_93fy8CyS2LTZMeta } from "/tmp/build_d80742d5/app/pages/sign/reset/[token].vue?macro=true";
import { default as indexw8O56lQtTFMeta } from "/tmp/build_d80742d5/app/pages/sign/tfa/index.vue?macro=true";
import { default as upl1ua6lj3tXMeta } from "/tmp/build_d80742d5/app/pages/sign/up.vue?macro=true";
import { default as _91token_93V2yP69NtjAMeta } from "/tmp/build_d80742d5/app/pages/sign/validation/[token].vue?macro=true";
export default [
  {
    name: "role",
    path: "/:role()",
    meta: _91role_93vn19ShF5AkMeta || {},
    redirect: "/:role()/home",
    component: () => import("/tmp/build_d80742d5/app/pages/[role].vue"),
    children: [
  {
    name: "role-calendar",
    path: "calendar",
    meta: calendarzv08NQC4T3Meta || {},
    alias: ["calendar"],
    component: () => import("/tmp/build_d80742d5/app/pages/[role]/calendar.vue")
  },
  {
    name: "role-chats",
    path: "chats",
    meta: chats59OpBeyMKiMeta || {},
    alias: ["chats"],
    component: () => import("/tmp/build_d80742d5/app/pages/[role]/chats.vue")
  },
  {
    name: "role-email-validation-token",
    path: "email/validation/:token()",
    meta: _91token_93rWB5psZBgxMeta || {},
    component: () => import("/tmp/build_d80742d5/app/pages/[role]/email/validation/[token].vue")
  },
  {
    name: "role-home",
    path: "home",
    meta: homed0HvrluBUCMeta || {},
    alias: ["home"],
    component: () => import("/tmp/build_d80742d5/app/pages/[role]/home.vue")
  },
  {
    name: "role-patients",
    path: "patients",
    meta: patients8W6CxUrM6xMeta || {},
    alias: ["patients"],
    component: () => import("/tmp/build_d80742d5/app/pages/[role]/patients.vue")
  },
  {
    name: "role-settings",
    path: "settings",
    meta: settingsSJ9NgreuzeMeta || {},
    alias: ["settings"],
    component: () => import("/tmp/build_d80742d5/app/pages/[role]/settings.vue")
  },
  {
    name: "role-users",
    path: "users",
    meta: userszBQqg0DYXuMeta || {},
    alias: ["users"],
    component: () => import("/tmp/build_d80742d5/app/pages/[role]/users.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexvBfDd72bnhMeta || {},
    component: () => import("/tmp/build_d80742d5/app/pages/index.vue")
  },
  {
    name: "print-recipe",
    path: "/print/recipe",
    meta: recipel4Iw2JKPygMeta || {},
    component: () => import("/tmp/build_d80742d5/app/pages/print/recipe.vue")
  },
  {
    name: "shift",
    path: "/shift",
    meta: shiftPAyJfzy0bVMeta || {},
    component: () => import("/tmp/build_d80742d5/app/pages/shift.vue")
  },
  {
    name: "sign-in",
    path: "/sign/in",
    meta: inYdIUksT8qhMeta || {},
    component: () => import("/tmp/build_d80742d5/app/pages/sign/in.vue")
  },
  {
    name: "sign",
    path: "/sign",
    meta: indexDZkjtWR1g2Meta || {},
    redirect: "/sign/in",
    component: () => import("/tmp/build_d80742d5/app/pages/sign/index.vue")
  },
  {
    name: "sign-reset-token",
    path: "/sign/reset/:token()",
    meta: _91token_93fy8CyS2LTZMeta || {},
    component: () => import("/tmp/build_d80742d5/app/pages/sign/reset/[token].vue")
  },
  {
    name: "sign-tfa",
    path: "/sign/tfa",
    meta: indexw8O56lQtTFMeta || {},
    component: () => import("/tmp/build_d80742d5/app/pages/sign/tfa/index.vue")
  },
  {
    name: "sign-up",
    path: "/sign/up",
    meta: upl1ua6lj3tXMeta || {},
    component: () => import("/tmp/build_d80742d5/app/pages/sign/up.vue")
  },
  {
    name: "sign-validation-token",
    path: "/sign/validation/:token()",
    meta: _91token_93V2yP69NtjAMeta || {},
    component: () => import("/tmp/build_d80742d5/app/pages/sign/validation/[token].vue")
  }
]