import { defineAsyncComponent } from 'vue'
export default {
  column: defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/column/index.vue").then(m => m.default || m)),
  "column-three": defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/column/three.vue").then(m => m.default || m)),
  default: defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/default.vue").then(m => m.default || m)),
  "discord-appbar": defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/discord/appbar.vue").then(m => m.default || m)),
  "discord-footer": defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/discord/footer.vue").then(m => m.default || m)),
  discord: defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/discord/index.vue").then(m => m.default || m)),
  "discord-main": defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/discord/main.vue").then(m => m.default || m)),
  "discord-navbar": defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/discord/navbar.vue").then(m => m.default || m)),
  "github-appbar": defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/github/appbar.vue").then(m => m.default || m)),
  "github-footer": defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/github/footer.vue").then(m => m.default || m)),
  github: defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/github/index.vue").then(m => m.default || m)),
  "github-main": defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/github/main.vue").then(m => m.default || m)),
  "github-navbar": defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/github/navbar.vue").then(m => m.default || m)),
  inbox: defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/inbox.vue").then(m => m.default || m)),
  osp: defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/osp.vue").then(m => m.default || m)),
  "router-view-template": defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/router-view.template.vue").then(m => m.default || m)),
  "scroll-top": defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/scroll-top.vue").then(m => m.default || m)),
  "sidebar-left": defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/sidebar/left.vue").then(m => m.default || m)),
  "sidebar-right": defineAsyncComponent(() => import("/tmp/build_d80742d5/app/layouts/sidebar/right.vue").then(m => m.default || m))
}